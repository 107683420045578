.header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    background-image: linear-gradient( to bottom,#0086FF 75% ,#0c60b6 25% );
    padding: 3vh;
    height: 16vh;
}

.header .header__logo {
    margin-top: -0.5vh;
}

.header .header__logo img{
    width: 30vh;
    margin-left: 3vh;
}
.header__container {
    display: flex;
}

.header .header__bars {
    font-size: 5vh;
    color: white;
}

.header .header__icons {
    font-size: 5vh;
    color: white;

}

.fa-shopping-cart {
    margin-left: 20px;
}