@font-face {
  font-family: 'Magalu Textos';
  src: url('./fonts/MagaluTextos-Light.ttf');
  font-weight: normal;
  font-style: normal;
}

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
  font-family: 'Magalu Textos';
  cursor: none;
}


html, body, #root {
  min-height: 100%;
  overflow: hidden;
  touch-action: none;
}

body {
  -webkit-font-smoothing: antialiased !important;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
   -khtml-user-select: none; /* Konqueror */
     -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none;
}
